.home-section {
    padding: 20px;
  }
  
  .countryContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
  }
  
  .countryDescriptionCard {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: transform 0.3s ease;
  }
  
  .countryDescriptionCard:hover {
    transform: scale(1.02);
  }
  
  .header {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .id {
    background-color: #4caf50;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  .descriptionImage {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    margin-top: 12px;
    border-radius: 8px;
  }
  
  .detailSection {
    margin: 12px 0;
  }
  
  .point {
    font-weight: bold;
    display: block;
    margin-bottom: 4px;
  }
  
  .description {
    font-size: 14px;
    color: #555;
  }

  
  .pagination {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
  
  .previousBtn, .nextBtn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem 0.5rem;
  }
  
  .previousBtn:hover, .nextBtn:hover {
    background-color: #0056b3;
  }
  
  
  /* Responsive Styles */
  @media screen and (min-width: 768px) {
    .countryContainer {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .countryDescriptionCard {
      flex: 1 1 calc(50% - 32px);
      margin: 16px;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .countryDescriptionCard {
      flex: 1 1 calc(33.33% - 32px);
    }
  }
  



  /* second page */
  .germany-step2-container {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif; 
    background: #f4f7fa; 
    min-height: 100vh; 
}

.germany-step2-main-content {
    width: 100%;
    background: #ffffff; 
    border-radius: 12px; 
    padding: 2.5rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); 
}

.germany-step2-intro-section {
    text-align: center;
    margin-bottom: 2rem; 
}

.germany-step2-heading-primary {
    font-size: 2.5rem; 
    color: #2c3e50; 
    margin-bottom: 1rem;
}

.germany-step2-image-cover {
    width: 100%;
    height: auto;
    border-radius: 12px; 
    margin: 1rem 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
}

.germany-step2-paragraph {
    font-size: 1.125rem; 
    color: #34495e; 
    margin: 1rem 0 2rem; 
}

.germany-step2-heading-secondary {
    font-size: 2rem;
    color: #2980b9; 
    margin: 2.5rem 0 1rem; 
    border-bottom: 2px solid #2980b9; 
    padding-bottom: 0.5rem;
}

.germany-step2-card {
    background: #ecf0f1; 
    border-radius: 8px;
    padding: 1.5rem; 
    margin: 1rem 0; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.germany-step2-card:hover {
    transform: translateY(-2px); 
}

.germany-step2-heading-tertiary {
    font-size: 1.5rem; 
    color: #2c3e50; 
    margin-bottom: 0.5rem; 
}

.germany-step2-institution-grid,
.germany-step2-university-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem; 
}

.germany-step2-requirement-list,
.germany-step2-proficiency-list,
.germany-step2-application-list,
.germany-step2-tuition-list {
    margin: 1.5rem 0; 
    padding-left: 1.5rem; 
    list-style-type: disc; 
    color: #34495e;
}

.germany-step2-requirement-list li,
.germany-step2-proficiency-list li,
.germany-step2-application-list li,
.germany-step2-tuition-list li {
    margin: 0.5rem 0;
}
@media (max-width: 768px) {
  .germany-step2-card {
      width: 100%; /* Make cards full width */
      margin: 0.5rem 0; /* Adjust margin for smaller screens */
  }
  .germany-step2-main-content {
    padding:0 2rem;
  }
}
