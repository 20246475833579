/* Container and main layout */
.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f5f7;
  }
  
  .main-content {
    flex: 1;
    /* max-width: 1200px; */
    /* margin: 2rem auto; */
    padding: 3rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
  }
  
  /* Headings */
  .heading-primary {
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333333;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  
  .heading-secondary {
    font-size: 2.2rem;
    color: #0056b3;
    margin-bottom: 1.5rem;
    font-weight: 600;
    border-bottom: 2px solid #0056b3;
    padding-bottom: 0.5rem;
  }
  
  .heading-tertiary {
    font-size: 1.6rem;
    color: #444444;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  /* Text and Details */
  .program-purpose, .program-details p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555555;
    margin-bottom: 1.2rem;
  }
  
  .program-purpose strong, .program-details strong {
    color: #333333;
  }
  
  /* Application process list */
  .application-steps {
    list-style: decimal inside;
    margin-left: 1rem;
    padding-left: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.8;
  }
  
  .application-steps li {
    margin-bottom: 0.8rem;
    padding-left: 0.2rem;
    color: #555555;
  }
  
  /* Hover effect on strong elements */
  .program-purpose strong, .program-details strong, .application-steps li strong {
    color: #333333;
    transition: color 0.3s ease;
  }
  
  .program-purpose strong:hover, .program-details strong:hover, .application-steps li strong:hover {
    color: #0056b3;
    cursor: pointer;
  }
  



  
  /* Media Queries for Responsiveness */
  @media (max-width: 1024px) {
    .main-content {
      padding: 2rem;
    }
  
    .heading-primary {
      font-size: 2.4rem;
    }
  
    .heading-secondary {
      font-size: 2rem;
    }
  
    .heading-tertiary {
      font-size: 1.4rem;
    }
  
    .previousBtn, .nextBtn {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .main-content {
      padding: 1.5rem;
    }
  
    .heading-primary {
      font-size: 2rem;
    }
  
    .heading-secondary {
      font-size: 1.8rem;
    }
  
    .heading-tertiary {
      font-size: 1.3rem;
    }
  
    .previousBtn, .nextBtn {
      padding: 0.75rem 1.25rem;
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .main-content {
      padding: 1rem;
    }
  
    .heading-primary {
      font-size: 1.8rem;
    }
  
    .heading-secondary {
      font-size: 1.6rem;
    }
  
    .heading-tertiary {
      font-size: 1.2rem;
    }
  
    .previousBtn, .nextBtn {
      padding: 0.6rem 1rem;
      font-size: 0.85rem;
    }
  }
  