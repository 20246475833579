.review-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}
/* Text */
.rw-text-content {
    width: 100%;
}
.rw-text-title {
    margin: 16px 0;
    color: #6F7074;
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: .7px;
}
.rw-text-num {
    color: #178BFF;
}
.rw-text-desc {
    margin: 16px 0;
    color: black;
    font-family: 'Rubik', sans-serif;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: .7px;
    line-height: 2.6rem;
}
.rw-text-format {
    margin: 64px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.rw-text-quote1 {
    position: absolute;
    top: -18px;
    left: -24px;
    color: #178BFF;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
}
.rw-text-quote2 {
    position: absolute;
    right: -18px;
    bottom: -24px;
    color: #178BFF;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
}
.rw-review {
   height: 40rem;
   width: 100%;
}
.rw-authors {
    margin: 0 0 0 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.rw-reviewer-name {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .8px;
}
.rw-reviewer-place {
    margin: 6px 0 0 0;
    color: #6F7074;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .8px;
}
/* Buttons */
.rw-next-btn {
    margin: 0 24px 0 0;
    color: black;
    border: 1px solid transparent;
    background-color: transparent;
    outline: transparent;
    font-size: 48px;
    cursor: pointer;
}
.rw-next-btn:hover {
    color: #178BFF;
}
/* Responsive */
@media screen and (max-width: 700px) {
    .review-section {
        letter-spacing: .8px;
    }
    .rw-text-title {
        font-size: 22px;
    }
    .rw-text-desc {
        font-size: 28px;
    }
    .rw-review {
        height: 20rem;
    }
    .rw-reviewer-name {
        font-size: 20px;
    }
    .rw-next-btn {
        margin: 0 16px 0 0;
        font-size: 40px;
    }
}
