.info-section {
    padding: 0 32px;
    text-align: center;
    background-color: white;
}
.info-title-content {
    margin: 64px 0 128px 0;
}
.info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.info-title > span {
    position: relative;
}
.info-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #F9980F;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.info-description {
    margin: 64px;
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}
/* Cards */
.info-cards-content {
    margin: 64px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}
.country-info-cards-content {
    margin: 64px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}
.info-cards {
    height: auto;
    border: 2px solid #d7d3d3;
    border-radius: 12px;
    background-color: white;
    position: relative;
}
.info-card-title {
    margin: 32px 0 40px 0;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    padding: 0 4px;
    font-weight: bold;
    letter-spacing: .8px;
}
.info-card-description {
    margin: 24px;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5rem;
}
.info-card-icon {
    position: absolute;
    top: -24px;
    left: 44%;
    padding: 10px;
    background-color: #DCE6FF;
    border-radius: 30px;
}
.info-fa-icon {
    color: #2C96FF;
    font-size: 24px;
}
.headerSection{
    display: flex;
    justify-content: space-between;
    padding: 4px;
    align-items: center;
}
.header-img{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    /* border: 1px solid red; */
}
.header-img:hover{
    transform: scale(1.05);
}
/* Responsive */
@media screen and (max-width: 1000px) {
    .info-description {
        margin: 64px 32px;
    }
    .info-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
    .country-info-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 700px) {
    .info-description {
        margin: 64px 8px;
    }
    .info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
    .country-info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}
